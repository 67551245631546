import Vue from 'vue/dist/vue.esm.js'

export default class CouponManager {
  constructor () {
    let app = document.getElementById('coupon-apply')
    if (!app) return

    // 現在の国コードを取得(layouts/front/application.html.erbで定義)
    const current_country = CURRENT_COUNTRY

    new Vue({
      el: app,
      data: {
        loading: false,
        form: {
          code: '',
          errors: {
            code: ''
          },
        },
      },
      computed: {
        // API URLを取得
        // @return [String]
        apiURL () {
          if (current_country == 'JP') {
            return '/carts/coupon.json'
          } else {
            return `/${current_country.toLowerCase()}/carts/coupon.json`
          }
        },
      },
      methods: {
        // @param  [String] name
        // @return [Boolean]
        isError (name) {
          return !!this.form.errors[name]
        },
        // @param  [String] name
        // @return [String]
        errorMessage (name) {
          return this.form.errors[name]
        },
        // クーポンを適用
        applyCoupon () {
          if (!this.loading) {
            this.loading = true
            $.ajax(this.apiURL, {
              type: 'post',
              data: {
                code: this.form.code,
              }
            }).done(() => {
              window.location.reload()
            }).fail((res) => {
              this.loading = false
              if (res.status == 400) {
                Object.keys(res.responseJSON.errors).forEach(key => {
                  this.$set(this.form.errors, key, res.responseJSON.errors[key])
                })
              } else {
                console.log(res)
              }
            })
          }
        },
      }
    })
  }
}

window.addEventListener('DOMContentLoaded',()=>{
  return

  console.log("DOMContentLoaded event")
  const buttonCouponApply = document.getElementById('coupon_apply')
  buttonCouponApply.onclick = (e)=>{
    console.log("click")

    $.ajax('/carts/coupon?code=114514', {type: 'post'}).done(res=>{
      
      console.log(res)
      
    })

    $.ajax(`/orders/confirm`, {type: 'get'}).done(res => {
      
      // DOMParserオブジェクト
      let parser = new DOMParser();
      // HTML文字列をパースし、documentオブジェクトを返す
      let doc = parser.parseFromString(res, "text/html");

      let target = document.getElementsByClassName("__container-inner")[0]
      let next = doc.getElementsByClassName("__container-inner")[0]
      
      
      //target.children[0] = next.children[0]
      //console.log(doc)
      //console.log(document)
      target.replaceWith(next)
      next.dispatchEvent(new Event("DOMContentLoaded", {
        bubbles: true,
        cancelable: true
      }));
      

      //console.log(doc.getElementsByClassName("__container-inner"))


      target
    })
  }

})